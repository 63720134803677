import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import { Option, OptionGroup, Select } from '@leafygreen-ui/select';
import TextInput from '@leafygreen-ui/text-input';
import CreatableSelect from 'baas-ui/common/components/creatable-select/CreatableSelect';
import { RulesPageCollectionExplorerContext } from 'baas-ui/rules/RulesPage';
import { useRulesPageContext } from 'baas-ui/rules/useRulesPageContext';
import { MongoDBNamespaceRule } from 'admin-sdk';
import './new-collection-modal.less';
export var TestSelector;
(function (TestSelector) {
    TestSelector["DataSourceSelect"] = "data-source-select";
    TestSelector["DatabaseSelect"] = "database-select";
    TestSelector["ExistingDatabaseOptions"] = "existing-database-options";
    TestSelector["CollectionSelect"] = "collection-select";
})(TestSelector || (TestSelector = {}));
const baseClassName = 'new-collection-modal';
const formRowClassName = `${baseClassName}__form-row`;
export const NewCollectionModal = ({ open, setOpen, defaultDataSource, defaultDatabase }) => {
    const { dataSources } = useContext(RulesPageCollectionExplorerContext);
    const { createCollection, partialDataSources } = useRulesPageContext();
    const dataSourceOptions = dataSources.map((service) => ({
        value: service.dataSourceName,
        label: service.dataSourceName,
    }));
    const [selectedDataSource, setSelectedDataSource] = useState(dataSourceOptions.find(({ value }) => value === defaultDataSource) || null);
    const [databaseOptions, setDatabaseOptions] = useState([]);
    const [selectedDatabase, setSelectedDatabase] = useState(null);
    const [collectionOptions, setCollectionOptions] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState('');
    const [isCollectionNameInvalid, setIsCollectionNameInvalid] = useState(false);
    React.useEffect(() => {
        const selectedDataSourceData = dataSources.find(({ dataSourceName }) => dataSourceName === selectedDataSource?.value);
        setDatabaseOptions((selectedDataSourceData?.databases || []).map((database) => ({
            value: database.databaseName,
            label: database.databaseName,
        })));
        setSelectedCollection('');
        setIsCollectionNameInvalid(false);
    }, [selectedDataSource, dataSources]);
    React.useEffect(() => {
        const selectedDataSourceData = dataSources.find(({ dataSourceName }) => dataSourceName === selectedDataSource?.value);
        const selectedDatabaseData = selectedDataSourceData?.databases.find(({ databaseName }) => databaseName === selectedDatabase?.value);
        setCollectionOptions(selectedDatabaseData?.collections.map((collectionData) => collectionData.collectionName) || []);
        setSelectedCollection('');
        setIsCollectionNameInvalid(false);
    }, [selectedDatabase, dataSources]);
    React.useEffect(() => {
        if (defaultDataSource) {
            setSelectedDataSource({ label: defaultDataSource, value: defaultDataSource });
        }
        if (defaultDatabase) {
            setSelectedDatabase({ label: defaultDatabase, value: defaultDatabase });
        }
    }, [defaultDataSource, defaultDatabase]);
    return (_jsxs(ConfirmationModal, { "data-cy": "new-collection-modal", className: baseClassName, open: open, confirmButtonProps: {
            children: 'Create',
            disabled: !selectedDatabase || !selectedCollection || isCollectionNameInvalid,
            onClick: () => {
                if (selectedDatabase && selectedCollection && !collectionOptions.includes(selectedCollection)) {
                    const dataSourceId = partialDataSources.find((dataSource) => dataSource.name === selectedDataSource?.value).id;
                    const newEmptyRule = new MongoDBNamespaceRule({
                        database: selectedDatabase?.value,
                        collection: selectedCollection,
                    });
                    createCollection(dataSourceId, selectedDataSource.value, selectedDatabase.value, selectedCollection, newEmptyRule);
                    setOpen(false);
                }
            },
        }, cancelButtonProps: {
            onClick: () => setOpen(false),
        }, title: "Create a collection", children: [_jsx("div", { "data-testid": TestSelector.DataSourceSelect, children: _jsx(Select, { label: "Data Source", placeholder: "Select a datasource", allowDeselect: false, name: "data-source-name", className: `${baseClassName}-select-ds ${formRowClassName}`, value: selectedDataSource?.value || '', usePortal: false, onChange: (value) => {
                        setSelectedDataSource({
                            value,
                            label: value,
                        });
                    }, children: dataSourceOptions.map((opt) => (_jsx(Option, { className: `${baseClassName}-select`, value: opt.value, "data-testid": `${TestSelector.DataSourceSelect}-${opt.value}`, children: opt.label }, opt.value))) }) }), _jsx("div", { className: formRowClassName, "data-testid": TestSelector.DatabaseSelect, children: _jsxs(CreatableSelect, { name: "database-select", label: "Database Name", id: "cluster", usePortal: false, innerSelectClassName: `${baseClassName}-select`, "data-cy": TestSelector.DatabaseSelect, placeholder: "Select or add a database name", createTextInputPlaceholder: "Create a new database name", createNewOptionText: "Add new database", disabled: !selectedDataSource, onCreateNewValue: (value) => {
                        setSelectedDatabase({
                            value,
                            label: value,
                        });
                        setDatabaseOptions([
                            ...databaseOptions,
                            {
                                value,
                                label: value,
                            },
                        ]);
                    }, onChange: (value) => {
                        setSelectedDatabase({
                            value,
                            label: value,
                        });
                    }, value: selectedDatabase?.label || '', isValidNewValue: (value) => !!value, children: [!databaseOptions.some((dbOpt) => dbOpt.label === selectedDatabase?.label) && selectedDatabase && (_jsx(Option, { "data-testid": "db-option", className: `${baseClassName}-select`, value: selectedDatabase.label, children: selectedDatabase.label })), _jsx(OptionGroup, { "data-testid": TestSelector.ExistingDatabaseOptions, className: `${baseClassName}-select`, label: "Existing Databases", children: databaseOptions.map((opt) => (_jsx(Option, { "data-testid": "db-option", className: `${baseClassName}-select`, value: opt.value, children: opt.label }, opt.value))) })] }) }), _jsx(TextInput, { className: formRowClassName, "data-testid": TestSelector.CollectionSelect, "data-cy": TestSelector.CollectionSelect, label: "Collection Name", placeholder: "Enter new collection name", value: selectedCollection, onChange: (e) => setSelectedCollection(e.target.value), onBlur: (e) => {
                    !!e.target.value && setIsCollectionNameInvalid(collectionOptions.includes(selectedCollection));
                }, errorMessage: "This collection already exists in the current namespace. Please enter a new name.", state: isCollectionNameInvalid ? 'error' : 'none' })] }));
};
export function useNewCollectionModal() {
    const [isOpen, setIsOpen] = React.useState(false);
    const [defaultDataSource, setDefaultDataSource] = React.useState('');
    const [defaultDatabase, setDefaultDatabase] = React.useState();
    return [
        _jsx(NewCollectionModal, { open: isOpen, setOpen: setIsOpen, defaultDataSource: defaultDataSource, defaultDatabase: defaultDatabase }),
        (dataSource, database) => {
            setDefaultDataSource(dataSource);
            setDefaultDatabase(database);
            setIsOpen(true);
        },
    ];
}
