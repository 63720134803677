import { createAction } from 'redux-act';
import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { MAX_PENDING_USER_COUNT } from './constants';
export const NAME = 'users/';
export const clearUserConfirmationStatus = createAction(`${NAME}clear user confirmation status`);
export const resetUsersAction = createAction(`${NAME}reset users`);
export const resetLastUserAction = createAction(`${NAME}reset last user`);
export const [addUserAction, addUser] = createActionsAndExecutor(`${NAME}add user`, (client, { groupId, appId, user }) => () => client.apps(groupId).app(appId).users().create(user));
export const [removeUserAction, removeUser] = createActionsAndExecutor(`${NAME}delete user`, (client, { groupId, appId, userId }) => () => client.apps(groupId).app(appId).users().user(userId).remove());
export const [enableUserActions, enableUser] = createActionsAndExecutor(`${NAME}enable user`, (client, { groupId, appId, userId }) => () => client.apps(groupId).app(appId).users().user(userId).enable());
export const [disableUserActions, disableUser] = createActionsAndExecutor(`${NAME}disable user`, (client, { groupId, appId, userId }) => () => client.apps(groupId).app(appId).users().user(userId).disable());
export const [loadUserDevicesActions, loadUserDevices] = createActionsAndExecutor(`${NAME}load user devices`, (client, { groupId, appId, userId }) => () => client.apps(groupId).app(appId).users().user(userId).devices().get());
export const [loadUserActions, loadUser] = createActionsAndExecutor(`${NAME}load user`, (client, { groupId, appId, userId }) => () => client.apps(groupId).app(appId).users().user(userId).get());
export const [loadUsersActions, loadUsers] = createActionsAndExecutor(`${NAME}load users`, (client, { groupId, appId, filter }) => () => client.apps(groupId).app(appId).users().list(filter));
export const [loadPendingUsersActions, loadPendingUsers] = createActionsAndExecutor(`${NAME}load pending users`, (client, { groupId, appId, filter }) => () => client.apps(groupId).app(appId).userRegistrations().listPending(MAX_PENDING_USER_COUNT, filter?.after));
export const [confirmUserByEmailActions, confirmUserByEmail] = createActionsAndExecutor(`${NAME}confirm user by email`, (client, { groupId, appId, email }) => () => client.apps(groupId).app(appId).userRegistrations().confirmByEmail(email));
export const [removePendingUserByEmailActions, removePendingUserByEmail] = createActionsAndExecutor(`${NAME}remove pending user by email`, (client, { groupId, appId, email }) => () => client.apps(groupId).app(appId).userRegistrations().removePendingUserByEmail(email));
export const [runUserConfirmationByEmailActions, runUserConfirmationByEmail] = createActionsAndExecutor(`${NAME}run user confirmation`, (client, { groupId, appId, email }) => () => client.apps(groupId).app(appId).userRegistrations().runUserConfirmation(email));
export const [revokeSessionsActions, revokeSessions] = createActionsAndExecutor(`${NAME}revoke user sessions`, (client, { groupId, appId, userId }) => client.apps(groupId).app(appId).users().user(userId).logout);
export const asyncEditRcvActions = [
    addUserAction.rcv,
    removeUserAction.rcv,
    enableUserActions.rcv,
    disableUserActions.rcv,
    revokeSessionsActions.rcv,
];
