import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import Button from '@leafygreen-ui/button';
import Modal from '@leafygreen-ui/modal';
import Stepper, { Step } from '@leafygreen-ui/stepper';
import { H3, Link } from '@leafygreen-ui/typography';
import classNames from 'classnames';
import lottie from 'lottie-web/build/player/lottie_light';
import deployAnimation from 'baas-ui/deploy/understanding-save-and-deploy-modal/DeployAnimation.json';
import saveAnimation from 'baas-ui/deploy/understanding-save-and-deploy-modal/SaveAnimation.json';
import { useDarkMode } from 'baas-ui/theme';
import './understanding-save-and-deploy-modal.less';
const baseClassName = 'understanding-save-and-deploy-modal';
export default function UnderstandingSaveAndDeployModal({ deploymentConfigUrl, open, onClose }) {
    const [animationContainer, setAnimationContainer] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const darkMode = useDarkMode();
    const openHandler = (isOpen) => {
        if (!isOpen) {
            onClose();
        }
    };
    const handleForwardButtonClick = () => {
        if (currentStep === 1) {
            onClose();
        }
        else {
            setCurrentStep(1);
        }
    };
    useEffect(() => {
        if (animationContainer) {
            const animation = currentStep === 0 ? saveAnimation : deployAnimation;
            lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: animation,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                    className: 'darkreader-invert',
                },
            });
        }
        return lottie.destroy;
    }, [animationContainer, currentStep]);
    return (_jsxs(Modal, { open: open, setOpen: openHandler, className: baseClassName, "data-cy": "understanding-save-and-deploy-modal", children: [_jsx(H3, { className: `${baseClassName}-header`, children: "Understanding Save and Deploy" }), _jsxs("div", { className: `${baseClassName}-header-text`, children: ["This is the first time you have used ", _jsx("b", { children: "Save" }), ". In App Services, Save is different from Deploy. Click through the deployment steps to understand each one."] }), _jsxs("div", { className: classNames(`${baseClassName}-container`, {
                    [`${baseClassName}-container-light-mode`]: !darkMode,
                    [`${baseClassName}-container-dark-mode`]: darkMode,
                }), children: [_jsxs(Stepper, { currentStep: currentStep, className: `${baseClassName}-stepper`, children: [_jsx(Step, { children: "Save Changes as Draft" }), _jsx(Step, { children: "Review Draft & Deploy" })] }), _jsx("div", { className: classNames(`${baseClassName}-animation`, 'darkreader-ignore-child-inline-style'), ref: setAnimationContainer }), _jsx("div", { className: `${baseClassName}-container-text`, children: currentStep === 0 ? ('Saving creates a Deployment Draft...') : (_jsxs(_Fragment, { children: ["Once a Draft is saved, you can ", _jsx("b", { children: "deploy" }), " it or ", _jsx("b", { children: "discard" }), " it."] })) })] }), _jsxs("div", { className: `${baseClassName}-text`, children: ["You can also", ' ', _jsx(Link, { className: `${baseClassName}-link`, hideExternalIcon: true, href: deploymentConfigUrl, target: "_blank", rel: "noopener noreferrer", children: _jsx("b", { children: "disable drafts" }) }), ' ', "if you feel like it, in which case you'd deploy directly."] }), _jsxs("div", { className: classNames(`${baseClassName}-footer`, {
                    [`${baseClassName}-footer-flex-end`]: currentStep === 0,
                    [`${baseClassName}-footer-space-between`]: currentStep === 1,
                }), children: [currentStep === 1 && (_jsx(Button, { className: `${baseClassName}-button`, onClick: () => setCurrentStep(0), children: "Back" })), _jsxs(Button, { className: `${baseClassName}-button`, onClick: handleForwardButtonClick, children: [currentStep === 0 && 'Next', currentStep === 1 && 'Got it!'] })] })] }));
}
