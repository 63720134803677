import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Badge from '@leafygreen-ui/badge';
import Box from '@leafygreen-ui/box';
import Icon from '@leafygreen-ui/icon';
import IconButton from '@leafygreen-ui/icon-button';
import { Menu, MenuItem } from '@leafygreen-ui/menu';
import { palette } from '@leafygreen-ui/palette';
import Tooltip from '@leafygreen-ui/tooltip';
import classNames from 'classnames';
import { useRulesPageContext } from 'baas-ui/rules/useRulesPageContext';
import { useDarkMode } from 'baas-ui/theme';
import './default-roles-filters-button.less';
export var TestSelector;
(function (TestSelector) {
    TestSelector["DefaultRolesFiltersButtonTooltip"] = "default-roles-filters-button-tooltip";
    TestSelector["DefaultRolesFiltersButtonContainer"] = "default-roles-filters-button-container";
    TestSelector["InfoIcon"] = "info-icon";
    TestSelector["LockIcon"] = "lock-icon";
    TestSelector["EllipsisIcon"] = "ellipsis-icon";
    TestSelector["EllipsisIconButton"] = "ellipsis-icon-button";
    TestSelector["EnabledBadge"] = "enabled-badge";
    TestSelector["ChevronIcon"] = "chevron-icon";
    TestSelector["DefaultRolesFiltersMenu"] = "default-roles-filters-menu";
    TestSelector["DeleteDefaultRule"] = "delete-default-rule";
    TestSelector["IncompatibleRolesIcon"] = "incompatible-roles-icon";
})(TestSelector || (TestSelector = {}));
const baseClassName = 'default-roles-filters-button';
export default function DefaultRolesFiltersButton({ dataSourceId, dataSourceName, updateCurrentDataSource }) {
    const [showMenu, setShowMenu] = React.useState(false);
    const [isOpenMenu, setIsOpenMenu] = React.useState(false);
    const { onClickDeleteAction, defaultRulesByDataSourcesId, deletingDSDefaultRules, syncIncompatibleRolesByDataSourceId, } = useRulesPageContext();
    const [isConfigured, setIsConfigured] = React.useState(!!defaultRulesByDataSourcesId[dataSourceId]);
    const [hasRoles, setHasRoles] = React.useState(defaultRulesByDataSourcesId[dataSourceId]?.roles?.length);
    const dataSourceSyncIncompatibleRoles = syncIncompatibleRolesByDataSourceId[dataSourceId];
    const hasSyncIncompatibleDefaultRoles = dataSourceSyncIncompatibleRoles &&
        dataSourceSyncIncompatibleRoles.defaultRoles &&
        dataSourceSyncIncompatibleRoles.defaultRoles?.length > 0;
    React.useEffect(() => {
        if (!isOpenMenu) {
            setShowMenu(false);
        }
    }, [isOpenMenu]);
    React.useEffect(() => {
        setIsConfigured(!!defaultRulesByDataSourcesId[dataSourceId]);
        setHasRoles(defaultRulesByDataSourcesId[dataSourceId]?.roles?.length);
    }, [defaultRulesByDataSourcesId]);
    const isDeletingFromCurrentDatasource = deletingDSDefaultRules.has(dataSourceId);
    const isDisabled = !defaultRulesByDataSourcesId[dataSourceId] || isDeletingFromCurrentDatasource;
    const statusIndicator = () => {
        if (!isConfigured) {
            return (_jsx(Tooltip, { "data-test-selector": TestSelector.DefaultRolesFiltersButtonTooltip, className: `${baseClassName}-overview-tooltip`, trigger: _jsx(Box, { children: _jsx(Icon, { "data-test-selector": TestSelector.InfoIcon, "data-testid": TestSelector.InfoIcon, "data-cy": TestSelector.InfoIcon, className: `${baseClassName}-info`, glyph: "InfoWithCircle", fill: palette.gray.base }) }), triggerEvent: "hover", children: "Default rules are sets of roles and filters that you define and add to every collection without Rules yet." }));
        }
        if (hasRoles) {
            if (hasSyncIncompatibleDefaultRoles) {
                return (_jsx(Tooltip, { className: `${baseClassName}-sync-incompatible-roles-tooltip`, justify: "middle", trigger: _jsx(Box, { "data-testid": TestSelector.IncompatibleRolesIcon, children: _jsx(Icon, { glyph: "ImportantWithCircle", fill: palette.yellow.dark2, className: `${baseClassName}-info` }) }), triggerEvent: "hover", children: "You have default roles that are incompatible with Sync" }));
            }
            return (_jsx(Badge, { variant: "green", className: `${baseClassName}-info`, "data-cy": TestSelector.EnabledBadge, "data-testid": TestSelector.EnabledBadge, children: "Enabled" }));
        }
        return (_jsx(Icon, { "data-cy": TestSelector.LockIcon, "data-testid": TestSelector.LockIcon, className: `${baseClassName}-info`, glyph: "Lock", fill: palette.gray.dark3 }));
    };
    const darkMode = useDarkMode();
    return (_jsxs("div", { "data-test-selector": TestSelector.DefaultRolesFiltersButtonContainer, "data-testid": TestSelector.DefaultRolesFiltersButtonContainer, "data-cy": "default-roles-filters-button", onClick: () => updateCurrentDataSource(), className: classNames(baseClassName, {
            [`${baseClassName}-light-mode`]: !darkMode,
            [`${baseClassName}-dark-mode`]: darkMode,
        }), onMouseEnter: () => setShowMenu(true), onMouseLeave: () => {
            if (!isOpenMenu)
                setShowMenu(false);
        }, 
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex: 0, children: [_jsxs("div", { className: classNames(`${baseClassName}-left`, {
                    'default-roles-filters-button-left-configured': isConfigured,
                }), children: ["Default roles and filters ", statusIndicator()] }), _jsxs("div", { className: `${baseClassName}-right`, children: [showMenu && (_jsx(Menu, { open: isOpenMenu, setOpen: () => setIsOpenMenu(!isOpenMenu), align: "bottom", justify: "end", "data-test-selector": TestSelector.DefaultRolesFiltersMenu, trigger: _jsx(IconButton, { "data-test-selector": TestSelector.EllipsisIconButton, "data-cy": "default-roles-filters-button-trigger", className: `${baseClassName}-ellipsis`, "aria-label": "Menu", onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }, children: _jsx(Icon, { "data-test-selector": TestSelector.EllipsisIcon, glyph: "Ellipsis", fill: palette.blue.base }) }), children: _jsx(MenuItem, { className: classNames({ 'collection-explorer__menu-item--destructive': !isDisabled }), "data-test-selector": TestSelector.DeleteDefaultRule, "data-cy": TestSelector.DeleteDefaultRule, disabled: isDisabled, onClick: () => {
                                onClickDeleteAction({
                                    dataSourceName,
                                    dataSourceId,
                                    isDefaultRule: true,
                                });
                            }, children: "Delete default roles and filters" }) })), _jsx(Icon, { "data-test-selector": TestSelector.ChevronIcon, className: `${baseClassName}-chevron`, glyph: "ChevronRight", fill: palette.gray.base, size: "small" })] })] }));
}
