var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { JsonObject, JsonProperty } from 'json2typescript';
let CreateAtlasAPIKeyRequest = class CreateAtlasAPIKeyRequest {
    desc = '';
    roles = [];
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('desc')
], CreateAtlasAPIKeyRequest.prototype, "desc", void 0);
__decorate([
    JsonProperty('roles')
], CreateAtlasAPIKeyRequest.prototype, "roles", void 0);
CreateAtlasAPIKeyRequest = __decorate([
    JsonObject('CreateAtlasAPIKeyRequest')
], CreateAtlasAPIKeyRequest);
export { CreateAtlasAPIKeyRequest };
let CreateAtlasAPIKeyResponse = class CreateAtlasAPIKeyResponse {
    desc = '';
    publicKey = '';
    privateKey = '';
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('desc')
], CreateAtlasAPIKeyResponse.prototype, "desc", void 0);
__decorate([
    JsonProperty('public_key')
], CreateAtlasAPIKeyResponse.prototype, "publicKey", void 0);
__decorate([
    JsonProperty('private_key')
], CreateAtlasAPIKeyResponse.prototype, "privateKey", void 0);
CreateAtlasAPIKeyResponse = __decorate([
    JsonObject('CreateAtlasAPIKeyResponse')
], CreateAtlasAPIKeyResponse);
export { CreateAtlasAPIKeyResponse };
