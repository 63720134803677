var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { JsonConvert, JsonConverter, JsonObject, JsonProperty } from 'json2typescript';
export var LogType;
(function (LogType) {
    LogType["AUTH"] = "auth";
    LogType["FUNCTION"] = "function";
    LogType["PUSH"] = "push";
    LogType["SERVICE"] = "service";
    LogType["TRIGGER"] = "trigger";
    LogType["GRAPHQL"] = "graphql";
    LogType["SYNC"] = "sync";
    LogType["SCHEMA"] = "schema";
    LogType["ENDPOINT"] = "endpoint";
    LogType["TRIGGER_ERROR_HANDLER"] = "trigger_error_handler";
})(LogType || (LogType = {}));
export var LogStatus;
(function (LogStatus) {
    LogStatus["SUCCESS"] = "success";
    LogStatus["ERROR"] = "error";
})(LogStatus || (LogStatus = {}));
export var LogPolicyType;
(function (LogPolicyType) {
    LogPolicyType["SINGLE"] = "single";
    LogPolicyType["BATCH"] = "batch";
})(LogPolicyType || (LogPolicyType = {}));
let LogPolicy = class LogPolicy {
    type = LogPolicyType.SINGLE;
    constructor(required) {
        Object.assign(this, required);
    }
};
__decorate([
    JsonProperty('type', String)
], LogPolicy.prototype, "type", void 0);
LogPolicy = __decorate([
    JsonObject('LogPolicy')
], LogPolicy);
let SingleLogPolicy = class SingleLogPolicy extends LogPolicy {
    constructor(required) {
        super({ ...required, type: LogPolicyType.SINGLE });
        Object.assign(this, required);
    }
};
SingleLogPolicy = __decorate([
    JsonObject('SingleLogPolicy')
], SingleLogPolicy);
export { SingleLogPolicy };
let BatchLogPolicy = class BatchLogPolicy extends LogPolicy {
    constructor(required) {
        super({ ...required, type: LogPolicyType.BATCH });
        Object.assign(this, required);
    }
};
BatchLogPolicy = __decorate([
    JsonObject('BatchLogPolicy')
], BatchLogPolicy);
export { BatchLogPolicy };
export var LogForwardingActionType;
(function (LogForwardingActionType) {
    LogForwardingActionType["FUNCTION"] = "function";
    LogForwardingActionType["COLLECTION"] = "collection";
})(LogForwardingActionType || (LogForwardingActionType = {}));
let LogForwardingAction = class LogForwardingAction {
    id = '';
    type = LogForwardingActionType.FUNCTION;
    constructor(required) {
        Object.assign(this, required);
    }
};
__decorate([
    JsonProperty('_id')
], LogForwardingAction.prototype, "id", void 0);
__decorate([
    JsonProperty('type', String)
], LogForwardingAction.prototype, "type", void 0);
LogForwardingAction = __decorate([
    JsonObject('LogForwardingAction')
], LogForwardingAction);
let FunctionForwardingAction = class FunctionForwardingAction extends LogForwardingAction {
    constructor(required) {
        super(required);
        Object.assign(this, required);
    }
};
FunctionForwardingAction = __decorate([
    JsonObject('FunctionForwardingAction')
], FunctionForwardingAction);
export { FunctionForwardingAction };
let CollectionForwardingAction = class CollectionForwardingAction extends LogForwardingAction {
    database = '';
    collection = '';
    constructor(required) {
        super(required);
        Object.assign(this, required);
    }
};
__decorate([
    JsonProperty('database')
], CollectionForwardingAction.prototype, "database", void 0);
__decorate([
    JsonProperty('collection', String)
], CollectionForwardingAction.prototype, "collection", void 0);
CollectionForwardingAction = __decorate([
    JsonObject('CollectionForwardingAction')
], CollectionForwardingAction);
export { CollectionForwardingAction };
const jsonConvert = new JsonConvert();
/* eslint-disable class-methods-use-this */
let ActionConverter = class ActionConverter {
    serialize(action) {
        const type = action.type ?? '';
        switch (type) {
            case LogForwardingActionType.FUNCTION:
                return jsonConvert.serializeObject(action, FunctionForwardingAction);
            case LogForwardingActionType.COLLECTION:
                return jsonConvert.serializeObject(action, CollectionForwardingAction);
            default:
                throw new Error('unexpected action type');
        }
    }
    deserialize(data) {
        if ('type' in data) {
            const type = data.type;
            switch (type) {
                case LogForwardingActionType.FUNCTION:
                    return jsonConvert.deserializeObject(data, FunctionForwardingAction);
                case LogForwardingActionType.COLLECTION:
                    return jsonConvert.deserializeObject(data, CollectionForwardingAction);
                default:
                    throw new Error('unexpected action type');
            }
        }
        throw new Error('unexpected action type');
    }
};
ActionConverter = __decorate([
    JsonConverter
], ActionConverter);
let LogForwarder = class LogForwarder {
    id = '';
    name = '';
    logTypes = [];
    logStatuses = [];
    policy = new LogPolicy();
    action = new LogForwardingAction();
    disabled = false;
    error = undefined;
    errorCoId = undefined;
    constructor(required) {
        Object.assign(this, required);
    }
};
__decorate([
    JsonProperty('_id')
], LogForwarder.prototype, "id", void 0);
__decorate([
    JsonProperty('name')
], LogForwarder.prototype, "name", void 0);
__decorate([
    JsonProperty('log_types', [String])
], LogForwarder.prototype, "logTypes", void 0);
__decorate([
    JsonProperty('log_statuses', [String])
], LogForwarder.prototype, "logStatuses", void 0);
__decorate([
    JsonProperty('policy')
], LogForwarder.prototype, "policy", void 0);
__decorate([
    JsonProperty('action', ActionConverter)
], LogForwarder.prototype, "action", void 0);
__decorate([
    JsonProperty('disabled')
], LogForwarder.prototype, "disabled", void 0);
__decorate([
    JsonProperty('error', String, true)
], LogForwarder.prototype, "error", void 0);
__decorate([
    JsonProperty('error_co_id', String, true)
], LogForwarder.prototype, "errorCoId", void 0);
LogForwarder = __decorate([
    JsonObject('LogForwarder')
], LogForwarder);
export { LogForwarder };
let CreateLogForwarderRequest = class CreateLogForwarderRequest {
    name = '';
    logTypes = [];
    logStatuses = [];
    policy = new LogPolicy();
    action = new LogForwardingAction();
    disabled = false;
    constructor(required) {
        Object.assign(this, required);
    }
};
__decorate([
    JsonProperty('name')
], CreateLogForwarderRequest.prototype, "name", void 0);
__decorate([
    JsonProperty('log_types', [String])
], CreateLogForwarderRequest.prototype, "logTypes", void 0);
__decorate([
    JsonProperty('log_statuses', [String])
], CreateLogForwarderRequest.prototype, "logStatuses", void 0);
__decorate([
    JsonProperty('policy')
], CreateLogForwarderRequest.prototype, "policy", void 0);
__decorate([
    JsonProperty('action', ActionConverter)
], CreateLogForwarderRequest.prototype, "action", void 0);
__decorate([
    JsonProperty('disabled')
], CreateLogForwarderRequest.prototype, "disabled", void 0);
CreateLogForwarderRequest = __decorate([
    JsonObject('CreateLogForwarderRequest')
], CreateLogForwarderRequest);
export { CreateLogForwarderRequest };
let PatchLogForwarderRequest = class PatchLogForwarderRequest {
    name = undefined;
    logTypes = undefined;
    logStatuses = undefined;
    policy = undefined;
    action = undefined;
    disabled = undefined;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('name', String, true)
], PatchLogForwarderRequest.prototype, "name", void 0);
__decorate([
    JsonProperty('log_types', [String], true)
], PatchLogForwarderRequest.prototype, "logTypes", void 0);
__decorate([
    JsonProperty('log_statuses', [String], true)
], PatchLogForwarderRequest.prototype, "logStatuses", void 0);
__decorate([
    JsonProperty('policy', LogPolicy, true)
], PatchLogForwarderRequest.prototype, "policy", void 0);
__decorate([
    JsonProperty('action', ActionConverter, true)
], PatchLogForwarderRequest.prototype, "action", void 0);
__decorate([
    JsonProperty('disabled', Boolean, true)
], PatchLogForwarderRequest.prototype, "disabled", void 0);
PatchLogForwarderRequest = __decorate([
    JsonObject('PatchLogForwarderRequest')
], PatchLogForwarderRequest);
export { PatchLogForwarderRequest };
